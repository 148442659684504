import React, { useState } from "react";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="containerLoader">
        <span></span>
      </div>
    </section>
  ) : (
    <>
      <h1 className="text-2xl font-bold text-center">Privacy Policy</h1>
      <p className="my-3">
        At GameVaults.Live, we are committed to protecting your privacy. This
        Privacy Policy outlines the types of information we collect, how we use
        it, and the measures we take to safeguard it.
      </p>

      <h6 className="font-bold mb-3 mt-6">1. Information We Collect</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Personal Information:</b> When you interact with our site, you may
          provide personal information such as your name, email address, and
          other details voluntarily. This information is typically collected
          when you create an account, subscribe to our newsletter, or contact
          us.
        </li>
        <li className="my-2">
          <b>Non-Personal Information:</b> We automatically collect non-personal
          information such as your IP address, browser type, device information,
          and browsing behavior. This helps us understand how our website is
          used and improve your experience.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">2. How We Use Your Information</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>To Provide and Improve Services:</b> We use your information to
          operate and enhance our website and services, including personalizing
          your experience and responding to your inquiries.
        </li>
        <li className="my-2">
          <b>Communication:</b> If you provide your email address, we may send
          you updates, newsletters, and promotional materials. You can opt-out
          of these communications at any time.
        </li>
        <li className="my-2">
          <b>Analytics:</b> We use non-personal information for analytics
          purposes to monitor and improve the performance of our website.
        </li>
        <li className="my-2">
          <b>Customer Support:</b> We use your information to assist with
          customer service inquiries and provide support.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">
        3. Cookies and Tracking Technologies
      </h6>
      <p className="my-3">
        We use cookies and similar tracking technologies to enhance your
        experience on our website. Cookies are small data files stored on your
        device that help us remember your preferences and provide a personalized
        experience.
      </p>
      <p className="my-3 font-bold">Types of Cookies We Use:</p>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Essential Cookies:</b> These are necessary for the website to
          function properly.
        </li>
        <li className="my-2">
          <b>Performance Cookies:</b> These help us analyze how you use our site
          and improve its performance.
        </li>
        <li className="my-2">
          <b>Functional Cookies:</b> These remember your preferences and
          settings.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">4. Sharing Your Information</h6>
      <p className="my-3">
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties. However, we may share non-personal information with
        third-party service providers who assist us in operating our website and
        services, provided they agree to keep this information confidential. We
        may also disclose your information if required by law or to protect our
        rights.
      </p>

      <h6 className="font-bold mb-3 mt-6">5. Data Security</h6>
      <p className="my-3">
        We implement appropriate security measures to protect your personal
        information. However, no system can be entirely secure, and we cannot
        guarantee the absolute security of your data.
      </p>

      <h6 className="font-bold mb-3 mt-6">6. Third-Party Links</h6>
      <p className="my-3">
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these sites. We
        encourage you to review their privacy policies.
      </p>

      <h6 className="font-bold mb-3 mt-6">7. Children's Privacy</h6>
      <p className="my-3">
        GameVaults.Live is not intended for children under the age of 13. We do
        not knowingly collect personal information from children under 13. If we
        become aware that we have inadvertently collected such information, we
        will take steps to delete it.
      </p>

      <h6 className="font-bold mb-3 mt-6">8. Changes to This Privacy Policy</h6>
      <p className="my-3">
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated revision date. We encourage you to
        review this Privacy Policy periodically.
      </p>

      <h6 className="font-bold mb-3 mt-6">9. Contact Us</h6>
      <p className="my-3">
        If you have any questions or concerns about this Privacy Policy, please
        contact us at contact@gamevaults.live.
      </p>
      <p className="my-3">
        Thank you for visiting GameVaults.Live. We value your privacy and are
        committed to protecting your personal information.
      </p>
    </>
  );
}
