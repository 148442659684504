import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
export default function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    setIsMenuOpen(true);
    setActiveLink(link);
  };
  return (
    <>
      <header className="bg-blue-500 text-white p-2">
        <nav className=" border-gray-200 ">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src="/navbar.png" className="h-15" alt="Game Vaults Live" />
            </Link>
            <div className="flex md:order-2">
              <button
                data-collapse-toggle="navbar-search"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-search"
                aria-expanded="false"
                onClick={handleMenuToggle}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
            <div
              className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
                isMenuOpen ? "hidden" : ""
              }`}
              id="navbar-search"
            >
              <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 bg-white md:bg-transparent ">
                <li>
                  <Link
                    to="/"
                    className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ${
                      activeLink === "home"
                        ? "font-bold text-lime-400  "
                        : "md:text-white"
                    }`}
                    onClick={() => handleLinkClick("home")}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 ${
                      activeLink === "about"
                        ? "font-bold text-lime-400"
                        : "md:text-white"
                    }`}
                    onClick={() => handleLinkClick("about")}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ${
                      activeLink === "privacy-policy"
                        ? "font-bold text-lime-400"
                        : "md:text-white"
                    }`}
                    onClick={() => handleLinkClick("privacy-policy")}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/term-condition"
                    className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ${
                      activeLink === "term-condition"
                        ? "font-bold text-lime-400"
                        : "md:text-white"
                    }`}
                    onClick={() => handleLinkClick("term-condition")}
                  >
                    Term Condition
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ${
                      activeLink === "contact-us"
                        ? "font-bold text-lime-400"
                        : "md:text-white"
                    }`}
                    onClick={() => handleLinkClick("contact-us")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
