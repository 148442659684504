import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <p>
        &copy; {currentYear}
        <span>
          <Link to="/" className="hover:underline ms-2">
            Game Vaults live
          </Link>
        </span>
      </p>
    </footer>
  );
}
