import React, { useState } from "react";

export default function TermCondition() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="containerLoader">
        <span></span>
      </div>
    </section>
  ) : (
    <>
      <h1 className="text-2xl font-bold text-center">Terms and Conditions</h1>
      <p className="my-3">
        Welcome to GameVaults.Live! These Terms and Conditions outline the rules
        and regulations for using our website and services. By accessing or
        using GameVaults.Live, you agree to comply with and be bound by these
        terms. If you do not agree with any part of these terms, you should not
        use our website.
      </p>

      <h6 className="font-bold mb-3 mt-6">1. Use of the Website</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Eligibility:</b> By using our site, you affirm that you are at
          least 13 years old. If you are under 13, you must have parental
          permission to use our services.
        </li>
        <li className="my-2">
          <b>License:</b> Unless otherwise stated, GameVaults.Live and/or its
          licensors own the intellectual property rights for all material on
          GameVaults.Live. All intellectual property rights are reserved. You
          may view and/or print pages from GameVaults.Live for your own personal
          use, subject to the restrictions set in these Terms and Conditions.
        </li>
        <li className="my-2">
          <b>Prohibited Uses:</b> You are prohibited from:
          <ul className="list-disc mx-3 space-y-1  list-inside">
            <li className="my-2">
              Republishing material from GameVaults.Live.
            </li>
            <li className="my-2">
              Selling, renting, or sub-licensing material from GameVaults.Live.
            </li>
            <li className="my-2">
              Reproducing, duplicating, or copying material from
              GameVaults.Live.
            </li>
            <li className="my-2">
              Redistributing content from GameVaults.Live (unless content is
              specifically made for redistribution).
            </li>
          </ul>
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">2. User Accounts</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Account Responsibility:</b> If you create an account on
          GameVaults.Live, you are responsible for maintaining the
          confidentiality of your account and password and for restricting
          access to your computer. You agree to accept responsibility for all
          activities that occur under your account or password.
        </li>
        <li className="my-2">
          <b>Accuracy of Information:</b> You agree to provide accurate and
          complete information when creating your account and to keep this
          information updated.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">3. Purchases and Payments</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Pricing:</b> All prices listed on our website are subject to change
          without notice. We strive to ensure that all pricing information is
          accurate, but errors may occur. If we discover an error in the price
          of any product or service you have ordered, we will inform you as soon
          as possible and offer you the option to reconfirm your order at the
          correct price or cancel it.
        </li>
        <li className="my-2">
          <b>Payment Methods:</b> We accept various payment methods, including
          credit/debit cards and PayPal. By providing payment information, you
          represent and warrant that the information is accurate and that you
          are authorized to use the payment method provided.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">4. Delivery and Access</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Digital Products:</b> Purchased digital products will be delivered
          electronically to the email address associated with your account or
          made available for download from your account page.
        </li>
        <li className="my-2">
          <b>Service Access:</b> We strive to ensure that our website and
          services are available at all times. However, we do not guarantee
          uninterrupted access and may suspend access for maintenance or other
          reasons.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">5. Returns and Refunds</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Digital Products:</b> All sales of digital products are final. We
          do not offer refunds or exchanges for digital products unless required
          by law.
        </li>
        <li className="my-2">
          <b>Physical Products:</b> Returns and refunds for physical products
          are subject to our Return Policy, which is available on our website.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">6. Disclaimers</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>No Warranties:</b> GameVaults.Live is provided "as is" without any
          representations or warranties, express or implied. We make no
          representations or warranties in relation to this website or the
          information and materials provided on this website.
        </li>
        <li className="my-2">
          <b>Limitations of Liability:</b> In no event shall GameVaults.Live,
          nor any of its officers, directors, and employees, be liable to you
          for anything arising out of or in any way connected with your use of
          this website, whether such liability is under contract, tort, or
          otherwise. GameVaults.Live, including its officers, directors, and
          employees, shall not be liable for any indirect, consequential, or
          special liability arising out of or in any way related to your use of
          this website.
        </li>
      </ul>

      <h6 className="font-bold mb-3 mt-6">7. Indemnification</h6>
      <p className="my-3">
        You agree to indemnify and hold harmless GameVaults.Live and its
        affiliates, officers, directors, employees, and agents from and against
        any and all claims, liabilities, damages, losses, and expenses
        (including reasonable attorneys' fees) arising out of or in connection
        with your use of the website or violation of these terms.
      </p>

      <h6 className="font-bold mb-3 mt-6">8. Governing Law</h6>
      <p className="my-3">
        These Terms and Conditions are governed by and construed in accordance
        with the laws of india. Any disputes arising out of or in connection
        with these terms shall be subject to the exclusive jurisdiction of the
        courts located in india.
      </p>

      <h6 className="font-bold mb-3 mt-6">
        9. Changes to These Terms and Conditions
      </h6>
      <p className="my-3">
        We reserve the right to amend these Terms and Conditions at any time.
        Any changes will be posted on this page with an updated revision date.
        Your continued use of the website following the posting of changes
        constitutes your acceptance of such changes.
      </p>

      <h6 className="font-bold mb-3 mt-6">10. Contact Information</h6>
      <p className="my-3">
        If you have any questions about these Terms and Conditions, please
        contact us at contact@gamevaults.live.
      </p>
    </>
  );
}
