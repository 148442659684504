import React, { useState } from "react";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="containerLoader">
        <span></span>
      </div>
    </section>
  ) : (
    <>
      <h1 className="text-2xl font-bold text-center">About Us</h1>
      <p className="my-3">
        Welcome to <b>GameVaults.Live!</b>
      </p>
      <p className="my-3">
        At GameVaults.Live, we are passionate about bringing you an exceptional
        collection of games that can be enjoyed anytime, anywhere. Our mission
        is to provide a dynamic platform where gamers of all types can find,
        play, and enjoy their favorite HTML5 games with ease.
      </p>

      <h6 className="font-bold">Who We Are</h6>
      <p className="my-2">
        We are a dedicated team of gaming enthusiasts, developers, and designers
        who believe in the power of HTML5 technology to deliver high-quality
        gaming experiences. With a love for innovation and a commitment to
        excellence, we work hard to curate and provide the best games for our
        users.
      </p>

      <h6 className="font-bold">What We Offer</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Diverse Game Collection:</b> Explore our extensive library of HTML5
          games, including action, adventure, puzzle, strategy, and more.
          Whether you're looking for a quick diversion or an immersive
          experience, we have something for everyone.
        </li>
        <li className="my-2">
          <b>Quality Assurance:</b> We carefully select and test each game to
          ensure it meets our high standards of quality and performance. Our
          goal is to provide you with a seamless and enjoyable gaming
          experience.
        </li>
        <li className="my-2">
          <b>Instant Access:</b> Play your favorite games directly in your web
          browser with no need for downloads or installations. Our platform is
          designed for convenience and accessibility.
        </li>
        <li className="my-2">
          <b>Community and Engagement:</b> : Join a vibrant community of gamers
          where you can share your experiences, compete in challenges, and
          connect with others who share your passion for gaming.
        </li>
      </ul>

      <h6 className="font-bold">Our Vision</h6>
      <p className="my-2">
        Our vision at GameVaults.Live is to become the leading destination for
        HTML5 gaming. We aim to create a space where gamers can discover new
        favorites, enjoy high-quality games, and connect with a like-minded
        community.
      </p>

      <h6 className="font-bold">Our Commitment</h6>
      <ul className="list-disc mx-3 space-y-1  list-inside">
        <li className="my-2">
          <b>Innovation:</b> We continuously strive to innovate and enhance our
          platform, bringing you the latest and most exciting HTML5 games.
        </li>
        <li className="my-2">
          <b>Quality:</b> We are committed to delivering a high-quality gaming
          experience by carefully curating and testing every game on our
          platform.
        </li>
        <li className="my-2">
          <b>Community:</b> We value our community and are dedicated to creating
          an inclusive and engaging environment for all gamers.
        </li>
        <li className="my-2">
          <b>Transparency:</b> : We believe in being transparent in all our
          operations, from game listings to user interactions.
        </li>
      </ul>

      <h6 className="font-bold">Get In Touch</h6>
      <p className="my-2">
        We love hearing from our community! If you have any questions, feedback,
        or just want to chat about games, feel free to reach out to us at
        contact@gamevaults.live.
      </p>
      <p className="my-2">
        Thank you for visiting GameVaults.Live. Happy gaming!
      </p>
    </>
  );
}
